import './About.css';
import { MenuBar } from '../../components/menu-bar/MenuBar';

export const About = () => {
  return (
    <div className="about">
      <MenuBar />
      <div className="about-content">
        <p className="about-title">Pracownia Architektoniczna Sebastian Krawczyk</p>
        <a className="about-email" href="mailto:BIURO@KRAWCZYKSEBASTIAN.PL">BIURO@KRAWCZYKSEBASTIAN.PL</a>
        <p>NIP: 5742074120</p>
      </div>
    </div>
  );
}